import React, { PureComponent } from 'react'
import { connect } from 'react-redux';
import { graphql } from 'gatsby';

import { setPageHeading } from '../state/app/app-actions';

class PhotographyPage extends PureComponent {
  constructor({ data }) {
    super();
    this.state = {
      pageData: data.contentfulPage
    };
  }

  componentDidMount() {
    this.props.updatePageHeading(this.state.pageData.heading);
  }

  render() {
    return (
      <div>
        <h1>{this.state.pageData.subHeading}</h1>
        <p dangerouslySetInnerHTML={{__html: this.state.pageData.mainContent.mainContent}} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    pageHeading
  } = state.app;

  return {
    pageHeading
  };
}

const mapDispatchToProps = (dispatch) => {
  const updatePageHeading = (heading) => dispatch(setPageHeading(heading));

  return {
    updatePageHeading
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhotographyPage);

export const query = graphql`
  query PhotographyPage {
    contentfulPage(title: {eq: "Photography"}) {
      heading
      subHeading
      mainContent {
        mainContent
      }
    }
  }
`;
